<template>
  <div 
    id="programme"
  >
    <Menu/>
    <b-container class="mt-3 pb-3">
      <img src="../assets/premier.png" alt="" width="60%">
      <img src="../assets/deuxieme.png" alt="" width="60%">
      <img src="../assets/troiseme.png" alt="" width="60%">
    </b-container>
  </div>
</template>
<script>
import Menu from './../components/Menu.vue'
export default {
  components: {
    Menu
  },
}
</script>
